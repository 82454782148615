const url = "https://node-sniperbot.mobiloitte.io"; // staging
// const url = "http://172.16.1.217:1984"; // local
// const url = "https://node.mbaacademy.asia"; // live
const ApiConfig = {
  login: `${url}/api/v1/user/login`,
  loginadmin: `${url}/api/v1/admin/login`,
  signup: `${url}/api/v1/user/register`,
  verify: `${url}/api/v1/user/verify`,
  verifyOTP: `${url}/api/v1/admin/verifyOTP`,
  forgot: `${url}/api/v1/user/forgot`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  changePassword: `${url}/api/v1/user/changePassword`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  changePasswordAdmin: `${url}/api/v1/admin/changePassword`,
  resend: `${url}/api/v1/user/resend`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  profile: `${url}/api/v1/user/profile`,
  getProfile: `${url}/api/v1/admin/getProfile`,
  editProfile: `${url}/api/v1/user/editProfile`,
  adminEditProfile: `${url}/api/v1/admin/editProfile`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,

  kyclist: `${url}/api/v1/admin/kyc/list`,
  viewKyc: `${url}/api/v1/admin/kyc/view`,
  approveOrReject: `${url}/api/v1/admin/kyc/approveOrReject`,
  listUser: `${url}/api/v1/admin/listUser`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  createPlan: `${url}/api/v1/plan/create`,
  editPlan: `${url}/api/v1/plan/edit`,
  viewPlan: `${url}/api/v1/plan/view`,
  Planlist: `${url}/api/v1/plan/list`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  delete: `${url}/api/v1/plan/delete/`,
};
export default ApiConfig;
